import React from "react";

function Footer() {
  return (
    <>
      <div className="w-full border-t-[1px] border-slate-200">
        <footer className="w-full bg-white py-[16px] px-[8px]">
          <p className="text-center text-[14px] font-sans font-normal">
            Copyright © 2024 Mesho Seller. All Rights Reserved.
          </p>
        </footer>
      </div>
    </>
  );
}

export default Footer;
