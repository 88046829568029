import { BUYNOWDATADETAILS, BUYNOWREMOVE } from "../actionType";

const init = {
  buydata: [],
};
export const buyNowDetailsReducer = (state = init, action) => {
  switch (action.type) {
    case BUYNOWDATADETAILS:
      return {
        buydata: [action.payload],
      };
      break;

      case BUYNOWREMOVE:
      return {
        buydata: [],
      };
      break;

    default:
      return state;
      break;
  }
};
